import React from "react"

import Header from "./header"

export default function LayoutRegular({ children }) {
  return (
    <div className="container-regular">
        <Header />
        {children}
    </div>
  )
}