import React from "react"
import LayoutRegular from "../components/layoutRegular"

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Lightbox from "../components/lightbox"
import SEO from "../components/seo"

const pageSEO = {title: "Photos", description: "Benjamin Engelis photos"}

const Photos = ({ data }) => (
    <LayoutRegular>
      <SEO title={pageSEO.title} description={pageSEO.description} />
      <h1>Photos</h1>
      <div className="divider"></div>
      <Lightbox images={data.galleryImages.edges} />
      <a href="https://www.dropbox.com/sh/6oecnvzp07emfz7/AABb2fquHy1bwG0ueFdssu40a?dl=0">
        <div className="callout callout-neutral endOfPageMargin">
          <div className="icon-container">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" style={{fill: 'white', height: '23px'}}><path d="M48.732 69.783L91.04 27.476l11.74 11.74-42.308 42.31z"/><path d="M51 3.424h19.054v60.21H51z"/><path d="M60.543 81.572L18.22 39.282l11.72-11.74L72.27 69.85zM9 99.575h103v17H9z"/><path d="M5.5 68.576h17v48h-17zm92 0h17v48h-17z"/></svg>
          </div>
          Download Press-Kit (Images)
        </div>
      </a>
    </LayoutRegular>
  )


Photos.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Photos

export const pageQuery = graphql`
query {
  galleryImages: allFile(filter: { sourceInstanceName: { eq: "gallery" }}, sort: {order: ASC, fields: name}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`