import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

class Lightbox extends Component {
  state = {
    showLightbox: false,
    selectedImage: 0,
  }

  componentDidMount = () => {
    window.addEventListener('keyup', this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keyup', this.handleKeyUp, false)
  }

  handleClick = (e, index) => {
    e.preventDefault()
    this.setState({ showLightbox: !this.state.showLightbox, selectedImage: index })
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  goBack = (event) => {
    event.stopPropagation(); // prevents the onClick event of the entire modal (which would close the modal instantly after opening it)
    if(this.state.selectedImage > 0) {
        this.setState({ selectedImage: this.state.selectedImage - 1 })
    }
  }

  goForward = (event) => {
    event.stopPropagation(); // prevents the onClick event of the entire modal (which would close the modal instantly after opening it)
    if (this.state.selectedImage < this.props.images.length - 1) {
        this.setState({ selectedImage: this.state.selectedImage + 1 })
    }
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (this.state.selectedImage > 0) {
          this.setState({ selectedImage: this.state.selectedImage - 1 })
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (this.state.selectedImage < this.props.images.length - 1) {
          this.setState({ selectedImage: this.state.selectedImage + 1 })
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
      }
    }
  }

  render() {
    /* console.log("HIER : "  + JSON.stringify(this.props)); */
    const { images } = this.props
    const { showLightbox, selectedImage } = this.state
    return (
      <Fragment>
        <Gallery>
          {images.map((img, i) => (
            <GalleryItem key={i}>
              <a href="" alt="Benjamin Engeli Image" className="lightbox-gallery" onClick={e => this.handleClick(e, i)}>
                <StyledImg fluid={img.node.childImageSharp.fluid} />
              </a>
            </GalleryItem>
          ))}
        </Gallery>

        <LightboxModal visible={showLightbox} className="lightbox" onClick={this.closeModal}>
        <Button className="lightbox" onClick={this.goBack} disabled={selectedImage === 0}>
                    <i className="arrow left"></i>
        </Button>
          <LightboxContent>
          
            <Img fluid={images[selectedImage].node.childImageSharp.fluid} />
            {
            /*<Controls>
              <Button onClick={this.closeModal}>Close</Button>
              <LeftRight>
                <Button onClick={this.goBack} disabled={selectedImage === 0}>
                  Prev
                </Button>
                <Button onClick={this.goForward} disabled={selectedImage === images.length - 1}>
                  Next
                </Button>
              </LeftRight>
            </Controls>*/
            }
          </LightboxContent>
        <Button className="lightbox" onClick={this.goForward} disabled={selectedImage === images.length - 1}>
                    <i className="arrow right"></i>
        </Button>
        </LightboxModal>
      </Fragment>
    )
  }
}


const StyledImg = styled(Img)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%; // or whatever
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 4rem;
  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  grid-gap: 15px;
  .gatsby-image-outer-wrapper {
    height: 100%;
  }
`

const GalleryItem = styled.div`
  position: relative;
`

const Button = styled.button``

const LightboxModal = styled.div`
  z-index: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`
const LightboxContent = styled.div`
  margin: 15px;
  max-width: 700px;
  width: 100%;
`

/* del:
 const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftRight = styled.div`
  button:first-child {
    margin-right: 10px;
  }
`*/

Lightbox.propTypes = {
  images: PropTypes.array.isRequired,
}

export default Lightbox